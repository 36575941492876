import React from "react";
import magnifyingGlassIcon from "../images/overlay-img/magnifying-glass.png";
import { useTranslation } from "react-i18next";

function Product(props) {
  const { t } = useTranslation();

  // Img Modal Id Target
  const imgTriggerModalId = `imgModal${props.id}`;

  console.log(props.product.specification.production_capacity);

  return (
    <div className="col-lg-3 col-md-4 col-sm-12 individual-prod-col">
      <h3 className="medicine-name">
        {t(`products_catalog.${props.product.translation_id}.name`)}
      </h3>
      <div className="img-overlay mx-auto">
        <img
          className="medicine-img"
          data-bs-toggle="modal"
          data-bs-target={`#${imgTriggerModalId}`}
          src={props.product.img}
          alt={props.product.name}
        />
        {/* <img className="medicine-img" src={props.img} alt={props.name} /> */}
        {/* Img Trigger Modal */}
        <div
          className="modal fade"
          id={imgTriggerModalId}
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5 w-100" id="exampleModalLabel">
                  <strong>
                    {t(`products_catalog.${props.product.translation_id}.name`)}
                  </strong>
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-5 align-self-center">
                    <img
                      className="medicine-img-modal"
                      src={props.product.img}
                      alt={props.product.name}
                    />
                  </div>
                  <div className="col-7 align-self-center">
                    <div className="info-wrapper">
                      {/* if exist show if doesn't exist don't show */}
                      <p>
                        <strong>
                          {t("products_catalog.title.specification_title")}
                        </strong>
                      </p>
                      <hr />
                      {/* {props.product.specification.color && <p><strong>{t('products_catalog.title.color')}</strong>{props.product.specification.color}</p>} */}
                      {props.product.specification.color && (
                        <p>
                          <strong>{t("products_catalog.title.color")}</strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.specification.color`
                          )}
                        </p>
                      )}
                      {props.product.specification.fiber_length && (
                        <p>
                          <strong>
                            {t("products_catalog.title.fiber_length")}
                          </strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.specification.fiber_length`
                          )}
                        </p>
                      )}
                      {props.product.specification.moisture && (
                        <p>
                          <strong>
                            {t("products_catalog.title.moisture")}
                          </strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.specification.moisture`
                          )}
                        </p>
                      )}
                      {props.product.specification.impurities && (
                        <p>
                          <strong>
                            {t("products_catalog.title.impurities")}
                          </strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.specification.impurities`
                          )}
                        </p>
                      )}
                      {props.product.specification.ph && (
                        <p>
                          <strong>{t("products_catalog.title.ph")}</strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.specification.ph`
                          )}
                        </p>
                      )}
                      {props.product.specification.compression_ratio && (
                        <p>
                          <strong>
                            {t("products_catalog.title.compression_ratio")}
                          </strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.specification.compression_ratio`
                          )}
                        </p>
                      )}
                      {props.product.specification.expansion && (
                        <p>
                          <strong>
                            {t("products_catalog.title.expansion")}
                          </strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.specification.expansion`
                          )}
                        </p>
                      )}
                      {props.product.specification.electrical_conductivity && (
                        <p>
                          <strong>
                            {t(
                              "products_catalog.title.electrical_conductivity"
                            )}
                          </strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.specification.electrical_conductivity`
                          )}
                        </p>
                      )}
                      {props.product.specification.length && (
                        <p>
                          <strong>{t("products_catalog.title.length")}</strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.specification.length`
                          )}
                        </p>
                      )}
                      {props.product.specification.weight_per_bunch && (
                        <p>
                          <strong>
                            {t("products_catalog.title.weight_per_bunch")}
                          </strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.specification.weight_per_bunch`
                          )}
                        </p>
                      )}
                      {props.product.specification.production && (
                        <p>
                          <strong>
                            {t("products_catalog.title.production")}
                          </strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.specification.production`
                          )}
                        </p>
                      )}
                      {props.product.specification.material && (
                        <p>
                          <strong>
                            {t("products_catalog.title.material")}
                          </strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.specification.material`
                          )}
                        </p>
                      )}
                      {props.product.specification.diameter && (
                        <p>
                          <strong>
                            {t("products_catalog.title.diameter")}
                          </strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.specification.diameter`
                          )}
                        </p>
                      )}
                      {props.product.specification.diameter_yarn && (
                        <p>
                          <strong>
                            {t("products_catalog.title.diameter_yarn")}
                          </strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.specification.diameter_yarn`
                          )}
                        </p>
                      )}
                      {props.product.specification.diameter_log && (
                        <p>
                          <strong>
                            {t("products_catalog.title.diameter_log")}
                          </strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.specification.diameter_log`
                          )}
                        </p>
                      )}
                      {props.product.specification.lengths && (
                        <p>
                          <strong>{t("products_catalog.title.lengths")}</strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.specification.lengths`
                          )}
                        </p>
                      )}
                      {props.product.specification.inner_core && (
                        <p>
                          <strong>
                            {t("products_catalog.title.inner_core")}
                          </strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.specification.inner_core`
                          )}
                        </p>
                      )}
                      {props.product.specification.application && (
                        <p>
                          <strong>
                            {t("products_catalog.title.application")}
                          </strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.specification.application`
                          )}
                        </p>
                      )}
                      {props.product.specification.size && (
                        <p>
                          <strong>{t("products_catalog.title.size")}</strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.specification.size`
                          )}
                        </p>
                      )}
                      {props.product.specification.lengths_roll && (
                        <p>
                          <strong>
                            {t("products_catalog.title.lengths_roll")}
                          </strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.specification.lengths_roll`
                          )}
                        </p>
                      )}
                      {props.product.specification.backing && (
                        <p>
                          <strong>{t("products_catalog.title.backing")}</strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.specification.backing`
                          )}
                        </p>
                      )}
                      {props.product.specification.construction && (
                        <p>
                          <strong>
                            {t("products_catalog.title.construction")}
                          </strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.specification.construction`
                          )}
                        </p>
                      )}
                      {props.product.specification.twist && (
                        <p>
                          <strong>{t("products_catalog.title.twist")}</strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.specification.twist`
                          )}
                        </p>
                      )}
                      {props.product.specification.warp_weft && (
                        <p>
                          <strong>
                            {t("products_catalog.title.warp_weft")}
                          </strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.specification.warp_weft`
                          )}
                        </p>
                      )}
                      {props.product.specification.type && (
                        <p>
                          <strong>{t("products_catalog.title.type")}</strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.specification.type`
                          )}
                        </p>
                      )}
                      {props.product.specification.lbs && (
                        <p>
                          <strong>{t("products_catalog.title.lbs")}</strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.specification.lbs`
                          )}
                        </p>
                      )}
                      {props.product.specification.length_pcs && (
                        <p>
                          <strong>
                            {t("products_catalog.title.length_pcs")}
                          </strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.specification.length_pcs`
                          )}
                        </p>
                      )}
                      {props.product.specification.length_yarn_pc && (
                        <p>
                          <strong>
                            {t("products_catalog.title.length_yarn_pc")}
                          </strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.specification.length_yarn_pc`
                          )}
                        </p>
                      )}
                      {props.product.specification.production_capacity_1 && (
                        <p>
                          <strong>
                            {t("products_catalog.title.production_capacity")}
                          </strong>
                        </p>
                      )}
                      {props.product.specification.production_capacity_1 && (
                        <p>
                          {t(
                            `products_catalog.${props.product.translation_id}.specification.production_capacity_1`
                          )}
                        </p>
                      )}
                      {props.product.specification.production_capacity_2 && (
                        <p>
                          {t(
                            `products_catalog.${props.product.translation_id}.specification.production_capacity_2`
                          )}
                        </p>
                      )}
                      <br />
                      <p>
                        <strong>
                          {t("products_catalog.title.packaging_title")}
                        </strong>
                      </p>
                      <hr />
                      {props.product.packaging.packed_in && (
                        <p>
                          <strong>
                            {t("products_catalog.title.packed_in")}
                          </strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.packaging.packed_in`
                          )}
                        </p>
                      )}
                      {props.product.packaging.description && (
                        <p>
                          <strong>
                            {t("products_catalog.title.description")}
                          </strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.packaging.description`
                          )}
                        </p>
                      )}
                      {props.product.packaging.packed && (
                        <p>
                          <strong>{t("products_catalog.title.packed")}</strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.packaging.packed`
                          )}
                        </p>
                      )}
                      {props.product.packaging.per_bag && (
                        <p>
                          <strong>{t("products_catalog.title.per_bag")}</strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.packaging.per_bag`
                          )}
                        </p>
                      )}
                      {props.product.packaging.packaging && (
                        <p>
                          <strong>
                            {t("products_catalog.title.packaging")}
                          </strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.packaging.packaging`
                          )}
                        </p>
                      )}
                      {props.product.packaging.size_roll && (
                        <p>
                          <strong>
                            {t("products_catalog.title.size_roll")}
                          </strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.packaging.size_roll`
                          )}
                        </p>
                      )}
                      {props.product.packaging.weight_roll && (
                        <p>
                          <strong>
                            {t("products_catalog.title.weight_roll")}
                          </strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.packaging.weight_roll`
                          )}
                        </p>
                      )}
                      {props.product.packaging.construction && (
                        <p>
                          <strong>
                            {t("products_catalog.title.construction_pack")}
                          </strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.packaging.construction`
                          )}
                        </p>
                      )}
                      {props.product.packaging.size_pcs && (
                        <p>
                          <strong>
                            {t("products_catalog.title.size_pcs")}
                          </strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.packaging.size_pcs`
                          )}
                        </p>
                      )}
                      {props.product.packaging.bale_dimension && (
                        <p>
                          <strong>
                            {t("products_catalog.title.bale_dimension")}
                          </strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.packaging.bale_dimension`
                          )}
                        </p>
                      )}
                      {props.product.packaging.bale_weight && (
                        <p>
                          <strong>
                            {t("products_catalog.title.bale_weight")}
                          </strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.packaging.bale_weight`
                          )}
                        </p>
                      )}
                      {props.product.packaging.dimension && (
                        <p>
                          <strong>
                            {t("products_catalog.title.dimension")}
                          </strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.packaging.dimension`
                          )}
                        </p>
                      )}
                      {props.product.packaging.weight && (
                        <p>
                          <strong>{t("products_catalog.title.weight")}</strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.packaging.weight`
                          )}
                        </p>
                      )}
                      {props.product.packaging.pellet_desc && (
                        <p>{props.product.packaging.pellet_desc}</p>
                      )}
                      {props.product.packaging.weight_pcs && (
                        <p>
                          <strong>
                            {t("products_catalog.title.weight_pcs")}
                          </strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.packaging.weight_pcs`
                          )}
                        </p>
                      )}
                      {props.product.packaging.one_hank && (
                        <p>
                          <strong>
                            {t("products_catalog.title.one_hank")}
                          </strong>
                          {t(
                            `products_catalog.${props.product.translation_id}.packaging.one_hank`
                          )}
                        </p>
                      )}
                      {props.product.packaging.loadability && (
                        <p>
                          <strong>
                            {t("products_catalog.title.loadability")}
                          </strong>
                        </p>
                      )}
                      {props.product.packaging.loadability.twenty_container && (
                        <p>
                          {t(
                            `products_catalog.${props.product.translation_id}.packaging.loadability.twenty_container`
                          )}
                        </p>
                      )}
                      {props.product.packaging.loadability.forty_container && (
                        <p>
                          {t(
                            `products_catalog.${props.product.translation_id}.packaging.loadability.forty_container`
                          )}
                        </p>
                      )}
                      {props.product.packaging.loadability
                        .forty_container_1 && (
                        <p>
                          {t(
                            `products_catalog.${props.product.translation_id}.packaging.loadability.forty_container_1`
                          )}
                        </p>
                      )}
                      <p className="product-note">
                        *{" "}
                        {t(
                          `products_catalog.${props.product.translation_id}.name`
                        )}{" "}
                        {t("products_catalog.title.production_note")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <img
          className="magnifying-glass"
          data-bs-toggle="modal"
          data-bs-target={`#${imgTriggerModalId}`}
          src={magnifyingGlassIcon}
          alt="magnifying glass"
        />
        {/* Magni Glass Toggle Modal */}
        {/* <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
             <div className="modal-dialog modal-dialog-centered modal-lg">
                 <div className="modal-content">
                 <img className="full-img-modal" src={props.img} alt="Full Sized Image" />
                 </div>
             </div>
             </div> */}
      </div>
    </div>
  );
}

export default Product;
