import React from "react";
import cocohuskproduct from "../images/featurette-img/cocohuskproduct.jpg";
import cocofiber from "../images/featurette-img/cocofiber.jpeg";
import cocopeat from "../images/featurette-img/cocopeat.jpeg";
import { Link } from "react-router-dom";

function Featurettes(props) {
  function handleClickProduct() {
    props.setButtonState("product");
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  return (
    <div className="row">
      <div className="col-lg-4">
        <img
          className="bd-placeholder-img rounded-circle"
          width="140"
          height="140"
          src={cocopeat}
          aria-label="Placeholder: 140x140"
          alt="viboost health-supplement"
        />

        <h2 className="fw-normal">Coco Peat</h2>
        <p>A naturally growing media made from the husk of a coconut.</p>
        {/* <p><button className="btn btn-dark" onClick={handleClickProduct}>Read More &raquo;</button></p> */}
      </div>
      <div className="col-lg-4">
        <img
          className="bd-placeholder-img rounded-circle"
          width="140"
          height="140"
          src={cocohuskproduct}
          aria-label="Placeholder: 140x140"
          alt="omestan anagelsic anagelsik"
        />

        <h2 className="fw-normal">Coco Husk Chips</h2>
        <p>Chips made out of the outer shells of the coconut.</p>
        {/* <p><button className="btn btn-dark" onClick={handleClickProduct}>Read More &raquo;</button></p> */}
      </div>
      <div className="col-lg-4">
        <img
          className="bd-placeholder-img rounded-circle"
          width="140"
          height="140"
          src={cocofiber}
          aria-label="Placeholder: 140x140"
          alt="omestan anagelsic anagelsik"
        />

        <h2 className="fw-normal">Coconut Fibre</h2>
        <p>Natural fibres extracted from the outer husk of coconut.</p>
        {/* <Link to="/products"><p><button className="btn btn-dark" onClick={handleClickProduct}>Read More &raquo;</button></p></Link> */}
      </div>
      <Link to="/products">
        <p>
          <button className="btn btn-dark" onClick={handleClickProduct}>
            Browse More &raquo;
          </button>
        </p>
      </Link>
    </div>
  );
}

export default Featurettes;
