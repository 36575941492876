import React from "react";
import { useInView } from 'react-intersection-observer';

function About() {

    const [ ref2, inView2 ] = useInView({
        /* Optional options */
        threshold: 0,
      });

    return (
        <div id="about" ref={ref2} className={`bg-img-about hidden-animation-about ${inView2 ? "show-about" : "" }`}>
        <div className="container custom-container-about">
            <h1><strong>About Us</strong></h1>
            <br/>
            <p>CF Trading Company is a leading manufacturer and exporter of premium coconut derivative products from Indonesia. Our product line includes coconut fiber, cocopeat, and coco chips. Our manufacturing process begins by processing coconut husks, drying the coconut fiber and cocopeat, and packaging our products for shipment.</p>
            <p>We pride ourselves on our commitment to quality and our ability to control our production process. Unlike many of our competitors, we rely mainly on our own production, which allows us to supply better quality products to our clients. We source our coconut husks from nearby plantations, ensuring that our products are of the highest quality.</p>
        </div>
        </div>
    )
}

export default About;