import React from "react";
import Carousel from "./Carousel";
import Featurettes from "./Featurettes";
import About from "./About";
import Contact from "./Contact";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";

function Home(props) {
  const { t } = useTranslation();
  //   const [productButtonState, setButtonState] = React.useState("");
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0,
  });
  return (
    <div>
      {<Carousel />}
      {
        <div
          id="featurettes"
          className="container marketing custom-container-featurette"
        >
          <div className="spacer"></div>
          <div ref={ref} className={`hidden-animation ${inView ? "show" : ""}`}>
            <h1 classsName="product-title">{t("products.title")}</h1>
            <p>{t("products.description")}</p>
            <br />
            <Featurettes setButtonState={props.setButtonState} />
          </div>
        </div>
      }
      {<About />}
      {<Contact />}
    </div>
  );
}

export default Home;
