import React from "react";
// import logocft from "../images/navbar-img/logo-cft.jpeg";
import logocft from "../images/navbar-img/LogoCFTRW.png";
import SelectLanguage from "./SelectLanguage.jsx";
import { useTranslation } from "react-i18next";

function Header(props) {
  const { t } = useTranslation();

  const [navbar, setNavbar] = React.useState(false);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  function handleNavClick() {
    props.setButtonState("");
  }

  function handleMenuToggle() {
    setIsMenuOpen(!isMenuOpen);
  }

  const changeBackground = () => {
    if (window.scrollY >= 80 || props.productButtonState !== "") {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <header>
      <nav
        className={
          navbar || isMenuOpen
            ? "navbar navbar-expand-md navbar-dark fixed-top navbar-customization navbar-scrolled"
            : "navbar navbar-expand-md navbar-dark fixed-top navbar-customization"
        }
      >
        <div className="container-fluid">
          <a
            className="navbar-brand navbar-brand-customization"
            onClick={handleNavClick}
            href="/"
          >
            <span>
              <img
                className="mekada-logo"
                src={logocft}
                alt="Logo PT Mekada Abadi"
              />
            </span>
          </a>
          <button
            onClick={handleMenuToggle}
            className="navbar-toggler collapsed d-flex d-md-none flex-column justify-content-around"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="toggler-icon top-bar"></span>
            <span className="toggler-icon middle-bar"></span>
            <span className="toggler-icon bottom-bar"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav ms-auto mb-2 mb-md-0">
              <li className="nav-item">
                <a
                  className="nav-link"
                  aria-current="page"
                  onClick={handleNavClick}
                  href="#featurettes"
                >
                  {t("navbar.nav_item_1")}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={handleNavClick} href="#about">
                  {t("navbar.nav_item_2")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  onClick={handleNavClick}
                  href="#contact"
                >
                  {t("navbar.nav_item_3")}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link disabled" href="/">
                  {t("navbar.nav_item_4")}
                </a>
              </li>
              <li className="nav-item">
                <SelectLanguage />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
