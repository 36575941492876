const products = [
  {
    id: 1,
    category: "Raw Material",
    name: "Cocofiber",
    translation_id: "coco_fiber",
    img: "https://media.istockphoto.com/id/1179571735/photo/close-up-of-the-structure-of-coco-coir-a-fabric-made-of-coconut-fiber-natural-background.jpg?s=612x612&w=0&k=20&c=WQdlfHrT3_R1s0rHoFWEEPhqyWuaHZESswfJLxeQg3k=",
    specification: {
      color: "Golden Brown",
      fiber_length: "5 - 25 cm (long 75%, short 25%)",
      moisture: "15% - 18%",
      impurities: "< 3%",
      production_capacity_1: "• 200 MT/month",
    },
    packaging: {
      bale_dimension: "100 x 75 x 50 cm",
      bale_weight: "100 - 110 kg",
      loadability: {
        twenty_container: "• 20' HC (70 bales, 8 MT)",
        forty_container: "• 40' HC (180 bales, 18 MT)",
      },
    },
  },
  {
    id: 2,
    name: "Cocopeat Block",
    translation_id: "cocopeat_block",
    category: "Processed Material",
    img: "https://5.imimg.com/data5/SELLER/Default/2023/8/332819947/AP/RN/KM/562159/organic-cocopeat-blocks-500x500.jpg",
    specification: {
      color: "Natural Brown",
      electrical_conductivity: "Low (<0.5 mS/cm) and High (>0.5 mS/cm)",
      moisture: "10% - 15%",
      ph: "5.2 - 6.8",
      compression_ratio: "1 : 5",
      expansion: "70 - 80 litre",
      production_capacity_1: "• 100 MT/month (low EC)",
      production_capacity_2: "• 250 MT/month (high EC)",
    },
    packaging: {
      dimension: "30 x 30 x 15 ± 3 cm",
      weight: "5 kg ± 200g",
      pellet_desc: "Each pallet holds around 220 blocks",
      loadability: {
        forty_container: "• 40' HC, 20 pallets",
      },
    },
  },
  {
    id: 3,
    category: "Processed Material",
    translation_id: "coco_chip",
    img: "https://i.ebayimg.com/images/g/y8wAAOSwF3FjMsZb/s-l1200.jpg",
    name: "Coco Chip",
    specification: {
      electrical_conductivity: "Low (<0.5 mS/cm) and High (>0.5 mS/cm)",
      moisture: "5% - 10%",
      ph: "5.2 - 6.8",
      impurities: "< 1%",
      production_capacity_1: "• 8 MT/month (cube)",
      production_capacity_2: "• 150 MT/month (irregular shape)",
    },
    packaging: {
      packed_in: "sack/block",
      dimension: "30 x 30 x 18 cm ± 3 cm",
      weight: "5 kg ± 200 gram",
      loadability: {
        forty_container: "• 40' HC, 8 MT (sack)",
        forty_container_1: "• 40' HC, 20 - 22 MT (Block)",
      },
    },
  },
  {
    id: 4,
    category: "Raw Material",
    name: "Cocopeat Powder",
    translation_id: "cocopeat_powder",
    img: "https://5.imimg.com/data5/SELLER/Default/2023/6/314972036/TN/JA/ZJ/130301782/agriculture-cocopeat-powder.png",
    specification: {
      color: "Natural Brown",
      electrical_conductivity: "Low (<0.5 mS/cm) and High (>0.5 mS/cm)",
      moisture: "30% - 45%",
      ph: "5.2 - 6.8",
      impurities: "< 3%",
      production_capacity_1: "• 100 MT/month (low EC)",
      production_capacity_2: "• 250 MT/month (high EC)",
    },
    packaging: {
      description: "LDPE polybag 30 x 40 x 70 cm",
      weight: "28 - 30 kg/bag",
      loadability: {
        forty_container: "• 40' HC, 750 - 800 bags (± 24 MT), No pallet",
        forty_container_1: "• 40' HC, ± 680 bags (± 22 MT), Palletize",
      },
    },
  },
  {
    id: 5,
    category: "Raw Material",
    name: "Coco Bristle",
    translation_id: "coco_bristle",
    img: "https://5.imimg.com/data5/SELLER/Default/2020/8/TY/XL/KH/2059930/coco-bristle-fiber-500x500.jpg",
    specification: {
      color: "Brown",
      length: "20 - 27 cm",
      moisture: " 15% - 18%",
      weight_per_bunch: "500 gram",
      impurities: "2% - 5%",
      production: "28 MT/month",
    },
    packaging: {
      packed: "Plastic / Bag",
      per_bag: "10.5 kg (21 bunches)",
      loadability: {
        twenty_container: "• 20' HC, 7 MT",
        forty_container: "• 40' HC, 14 MT",
      },
    },
  },
  {
    id: 6,
    category: "Raw Material",
    img: "https://www.allstakesupply.com.au/wp-content/uploads/2017/07/Coir-log-website-banner--scaled.jpg",
    name: "Coir Log",
    translation_id: "coir_log",
    specification: {
      material: "Cocofiber",
      diameter_yarn: "6 - 8 mm",
      diameter_log: "30 cm",
      lengths: "300 cm",
      inner_core: "Cocofiber",
      application: "Erosion Control",
    },
    packaging: {
      weight_pcs: "25 kg",
      loadability: {
        forty_container: "• 40' HC (250 pcs)",
      },
    },
  },
  {
    id: 7,
    category: "Processed Material",
    img: "https://5.imimg.com/data5/SELLER/Default/2020/10/RA/IU/NX/7037221/coir-micro-green-grow-mat-500x500.jpg",
    name: "Coir Mat",
    translation_id: "coir_mat",
    specification: {
      material: "Cocofiber",
      size: "1 m x 10 m",
      diameter_yarn: "35 mm",
      twist: "12 - 13 / 10 cm",
    },
    packaging: {
      packaging: "Roll",
      loadability: {
        forty_container: "• 40' HC (110 rolls)",
      },
    },
  },
  {
    id: 8,
    category: "Processed Material",
    name: "Coir Rope",
    translation_id: "coir_rope",
    img: "https://5.imimg.com/data5/SELLER/Default/2022/12/GK/LE/HB/3731203/coir-ropes-500x500.jpg",
    specification: {
      material: "Cocofiber",
      diameter_yarn: "12 mm, 16 mm, 18 mm",
      diameter_log: "35 cm",
      lengths_roll: "180 - 200 m",
      inner_core: "Cottton 100%",
    },
    packaging: {
      loadability: {
        forty_container: "• 40' HC (900 rolls)",
      },
      weight_pcs: "25 - 27 kg",
    },
  },
  {
    id: 9,
    category: "Processed Material",
    img: "https://m.media-amazon.com/images/I/81j3kfaTKbS._AC_UF894,1000_QL80_.jpg",
    name: "Doormat",
    translation_id: "doormat",
    specification: {
      material: "100% Cocofiber",
      backing: "Natural Latex",
      construction: "Panama / Diagonal",
    },
    packaging: {
      packaging: "Pallet or Paper Pallet",
      size_roll: "2.1 x 40 m",
      size_pcs: "50 cm x 70 cm (custom)",
      weight: "0.8 - 0.9 kg/pcs",
      loadability: {
        forty_container: "• 40' HC (12,000 pcs)",
      },
    },
  },
  {
    id: 10,
    category: "Processed Material",
    name: "Coir Geotextile",
    translation_id: "coir_geotextile",
    img: "https://5.imimg.com/data5/SELLER/Default/2023/7/325092392/UG/CV/RZ/453789/200-gsm-brown-coir-geotextile.png",
    specification: {
      material: "100% Cocofiber",
      warp_weft: "4 x 4 cm , 5 x 5 cm (custom)",
      diameter_yarn: "4 - 5 mm (custom)",
    },
    packaging: {
      packaging: "Bale (40 sqm)",
      size_roll: "2 x 20 m (custom)",
      weight_roll: "14 kg",
      construction: "Panama 1/1",
      loadability: {
        forty_container: "• 40' HC (800 bale)",
      },
    },
  },
  {
    id: 11,
    name: "Coir Twine",
    translation_id: "coir_twine",
    category: "Processed Material",
    img: "https://i.ebayimg.com/images/g/aDQAAOSwrzFjwsLH/s-l1200.webp",
    specification: {
      material: "100% Cocofiber",
      diameter: "6 - 7 mm",
      type: "2 ply",
      lbs: "80 - 90",
      length_pcs: "5.8, 6, 6.4 m (custom)",
    },
    packaging: {
      packaging: "100 pcs yarn/hank",
      one_hank: "100 pcs (24 hank/bale)",
      loadability: {
        forty_container: "• 40' HC (140 bales)",
      },
    },
  },
  {
    id: 12,
    name: "Coir Twine Net",
    translation_id: "coir_twine_net",
    category: "Processed Material",
    img: "https://storage.googleapis.com/production-websitebuilder-v1-0-9/489/33489/YiLNLjbb/05247e78ff8d494ca5a7cf58a3b03526",
    specification: {
      material: "Cocofiber",
      size: "90 cm x 130 cm",
      diameter_yarn: "5 - 6 mm",
      twist: "12 - 13 / 10 cm",
      length_yarn_pc: "200 m",
    },
    packaging: {
      packaging: "10 pcs / bale",
      loadability: {
        forty_container: "• 40' HC (6300 pcs)",
      },
    },
  },
];

export default products;
