import React from "react";
import cftCover1 from "../images/carousel-img/cft-cover1.jpeg";
import cftCover2 from "../images/carousel-img/huskchipprod.jpeg";
import cftCover3 from "../images/carousel-img/cofiber.jpeg";
import { useTranslation } from "react-i18next";

function Carousel() {
  // Use Translation

  const { t } = useTranslation();

  // Bypass React JS and Bootstrap JS clashing to initiate slide on load data-bs-ride carousel. Note to only use RBS next time or TW

  React.useEffect(() => {
    // Delay in milliseconds before triggering the next carousel slide
    const delay = 5000; // Adjust this value as needed

    console.log("Carousel useEffect triggered");

    const nextSlide = () => {
      const nextButton = document.getElementsByClassName(
        "carousel-control-next-icon"
      )[0];
      if (nextButton) {
        nextButton.click();
        console.log("Clicked next slide");
      }
    };

    // Use setTimeout to trigger the next slide after the specified delay
    const slideTimer = setTimeout(nextSlide, delay);

    // Clear the timeout on component unmount to avoid memory leaks
    return () => {
      clearTimeout(slideTimer);
      console.log("Carousel useEffect cleanup");
    };
  }, []);

  return (
    <div
      id="myCarousel"
      className="carousel slide carousel-fade carousel-customize"
      data-bs-ride="carousel"
    >
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <div className="image-overlay"></div>{" "}
          {/* Overlay for the first image */}
          <img
            alt="background-cf-trading-1"
            className="bd-placeholder-img carousel-img-custom"
            src={cftCover1}
            aria-hidden="true"
            preserveAspectRatio="xMidYMid slice"
            focusable="false"
          />
          <div className="container">
            <div className="carousel-caption text-start carousel-custom-text">
              <h1>
                <strong>{t("carousel.title_slide_1")}</strong>
              </h1>
              <p>{t("carousel.description_slide_1")}</p>
              <p>
                <a className="btn btn-lg btn-success" href="#about">
                  {t("carousel.button_slide_1")}
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <div className="image-overlay"></div>{" "}
          {/* Overlay for the second image */}
          <img
            alt="background-cf-trading-2"
            className="bd-placeholder-img carousel-img-custom"
            src={cftCover2}
            aria-hidden="true"
            preserveAspectRatio="xMidYMid slice"
            focusable="false"
          />
          <div className="container">
            <div className="carousel-caption carousel-custom-text">
              <h1>{t("carousel.title_slide_2")}</h1>
              <p>
                <a className="btn btn-lg btn-success" href="#featurettes">
                  {t("carousel.button_slide_2")}
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <div className="image-overlay"></div>{" "}
          {/* Overlay for the third image */}
          <img
            alt="background-cf-trading-3"
            className="bd-placeholder-img carousel-img-custom"
            src={cftCover3}
            aria-hidden="true"
            preserveAspectRatio="xMidYMid slice"
            focusable="false"
          />
          <div className="container">
            <div className="carousel-caption text-end carousel-custom-text">
              <h1>{t("carousel.title_slide_3")}</h1>
              <p>
                <a className="btn btn-lg btn-success disabled" href="/">
                  {t("carousel.button_slide_3")}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#myCarousel"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#myCarousel"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
}

export default Carousel;
