import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Header from "./Header";
import products from "../products.js";
import Partitioner from "./Partitioner";
import Footer from "./Footer";
import Home from "./Home";

function App() {
  const [productButtonState, setButtonState] = React.useState("");

  return (
    <div>
      <Header
        productButtonState={productButtonState}
        setButtonState={setButtonState}
      />
      <Routes>
        <Route
          path="/"
          element={
            <Home
              products={products}
              productButtonState={productButtonState}
              setButtonState={setButtonState}
            />
          }
        />
        <Route
          exact
          path="/products"
          element={
            productButtonState === "product" ? (
              <Partitioner
                products={products}
                setButtonState={setButtonState}
              />
            ) : (
              <Navigate replace to={"/"} />
            )
          }
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
