import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { FaChevronDown } from "react-icons/fa";
import styled from "styled-components";
import ReactCountryFlag from "react-country-flag";

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-left: 2rem;
  display: flex;
  justify-content: center;
`;

const DropdownButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  color: #4a5568;
  background-color: #edf2f7;
  border: 1px solid #cbd5e0;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.2s ease, border-color 0.2s ease;

  &:hover {
    background-color: #e2e8f0;
  }
`;

const ChevronIcon = styled(FaChevronDown)`
  color: #1a202c;
`;

const FlagIcon = styled(ReactCountryFlag)`
  width: 1.5rem;
  height: 1rem;
`;

const DropdownItem = styled.a`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: ${(props) => (props.active ? "#1a202c" : "#4a5568")};
  background-color: ${(props) => (props.active ? "#f7fafc" : "transparent")};
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;

  &:hover {
    background-color: #edf2f7;
  }
`;

const DropdownHeader = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0rem 1rem;
  font-size: 14px;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 10;
  width: 160px;
  background-color: white;
  border: 1px solid #cbd5e0;
  border-radius: 0.25rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

const DropdownItemsContainer = styled.div`
  padding: 0.5rem 0;
`;

const SelectLanguage = ({ flip = false }) => {
  const { t, i18n } = useTranslation();
  const [showItems, setShowItems] = useState(false);

  const languageMap = {
    en: {
      label: t("language.english"),
      flag: "US",
    },
    id: {
      label: t("language.chinese"),
      flag: "CN",
    },
    // Add more languages and flags as needed
  };

  return (
    <DropdownContainer>
      <DropdownButton onClick={() => setShowItems(!showItems)}>
        <FlagIcon
          countryCode={languageMap[i18n.language.substring(0, 2)]?.flag}
        />
        {languageMap[i18n.language.substring(0, 2)]?.label}
        <ChevronIcon />
      </DropdownButton>

      {showItems && (
        <DropdownMenu className={flip ? "flip" : ""}>
          <DropdownItemsContainer>
            <DropdownHeader>{t("language.select_language")}</DropdownHeader>
            {Object.keys(languageMap).map((item) => (
              <DropdownItem
                key={item}
                active={i18n.language.substring(0, 2) === item}
                onClick={() => {
                  i18next.changeLanguage(item);
                  setShowItems(!showItems);
                }}
              >
                <FlagIcon countryCode={languageMap[item].flag} />
                {languageMap[item].label}
              </DropdownItem>
            ))}
          </DropdownItemsContainer>
        </DropdownMenu>
      )}
    </DropdownContainer>
  );
};

export default SelectLanguage;
