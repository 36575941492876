import React from "react";
import Product from "./Product";

function Products(props) {
  return (
    <div className="container text-center">
      <div className="row">
        {props.products.map((element, index) => {
          return <Product key={element.id} id={index} product={element} />;
        })}
      </div>
    </div>
  );
}

export default Products;
